import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { Intro } from '../components/Intro/Intro';
import Connect from '../components/Connect';
import { StyledLink } from '../components/StyledLink';
import Projects from '../components/Projects';
import { Section, Container, CSSGrid, Col } from '../components/Grid';
import { Box } from '../components/Box';
import { CardLink, CardContainer, CardBody } from '../components/Card';
import { designTokens } from '../components/Theme/designTokens';
import ImageTest from '../components/ImageTest';
import { ButtonLinkGhost } from '../components/Button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Product Designer" keywords={['portfolio', 'Ryan Parag', 'designer', 'UI/UX', 'product designer']} mdxType="SEO" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <div style={{
              width: designTokens.space[9],
              marginTop: designTokens.space[8]
            }}>
          <ImageTest filename={'thanks.png'} mdxType="ImageTest" />
        </div>
        <h4>Hello, I'm Ryan 👋 and I want to work at Stripe!</h4>
        <p className="lead">I'm a Product Designer currently based in Tampa, FL, but am hoping to relocate to San Francisco.</p>
        <Connect mdxType="Connect" />
        <br />
        <br />
        <ButtonLinkGhost marginRight={designTokens.space[3]} color={({
              theme
            }) => theme.highlightedText} to={'/about/resume'} mdxType="ButtonLinkGhost">Resumé</ButtonLinkGhost>
        <ButtonLinkGhost marginRight={designTokens.space[3]} color={({
              theme
            }) => theme.highlightedText} to={'/about/'} mdxType="ButtonLinkGhost">More about me</ButtonLinkGhost>
        <hr />
        <p>I was excited 🤩 when I saw your open position for a <a href="https://stripe.com/jobs/listing/product-designer/1983294" target="_blank">Product Designer at Stripe</a> and thought not only could it be a great match that lines up with my experience as a cross-functional design team member, but would also be an absolute blessing to help create value with a passionate, innovative, and impactful design team. My current team uses Stripe for payment processing in our digital product!</p>
        <p>Currently, I help design digital products for a global door manufacturer, <Link to={'work/masonite'} mdxType="Link">Masonite</Link> 🚪 - working as a solo designer alongside a team of software engineers and PM's. We're building complex R&amp;D products to help bridge the gap between contractors and homeowners. Take a look at a few of our projects:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">Password: <strong>guyfieri</strong></Box>
        <CardLink to={'/work/masonite/door-configurator'} mdxType="CardLink">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <strong>Door Configurator</strong>
              <br />
              <small>Creating compatible doors through a simpler, responsive, and accessible interface</small>
            </CardBody>
          </CardContainer>
        </CardLink>
        <CardLink to={'/work/masonite/advisar'} mdxType="CardLink">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <strong>Advisar</strong>
              <br />
              <small>An eCommerce management platform for contractors and internal team members</small>
            </CardBody>
          </CardContainer>
        </CardLink>
        <CardLink to={'/work/masonite/masonite-connected'} mdxType="CardLink">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <strong>Masonite Connected</strong>
              <br />
              <small>An "Internet of Things" experience that connects a door to a user's smart home</small>
            </CardBody>
          </CardContainer>
        </CardLink>
        <p>Before my time at Masonite, I was a solo product designer at a fintech company 💳 - <Link to={'work/masonite'} mdxType="Link">Chargebacks911</Link>, a company that provides solutions for businesses dealing with falsely protested credit card charges by intelligently managing payment disputes. Take a look at some of our major projects:</p>
        <CardLink to={'/work/disputelab'} mdxType="CardLink">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <strong>DisputeLab</strong>
              <br />
              <small>Enabling financial enterprises to filter, optimize, and submit thousands of disputes</small>
            </CardBody>
          </CardContainer>
        </CardLink>
        <CardLink to={'/work/chargebacks911/merchant-app'} mdxType="CardLink">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <strong>Wizard & Merchant App</strong>
              <br />
              <small>An onboarding experience and management application for clients</small>
            </CardBody>
          </CardContainer>
        </CardLink>
        <p>I would love the opportunity to not only share my work with you, but also to hear more about the role and how things work at Stripe. You can see my recent work <Link to={'/work/'} mdxType="Link">here</Link> (or use one of the links above), but if you have any other questions or would like to see a more in-depth look at my design process, I’d be pleased to walk you through it. I look forward to hearing from you.</p>
        <h5>Thanks for reading!</h5>
        <p>
          <br />
          <strong>Ryan Parag</strong>
          <br />
          <a href="mailto:parag.ryan@gmail.com?subject=Stripe - Product Designer">parag.ryan@gmail.com</a>
          <br />
          <a href="tel:9417356602">(941) 735-6602</a>
        </p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      